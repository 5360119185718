<template>
  <ContentContainer>
    <template #title>
      <h3>Reporting for: {{ getClientInContext.client_name }}</h3>
    </template>
    <div class="reporting-info">
      <div class="row">
        <b-card class="col mr-3">
          <h2 class="font-weight-bold">Workflow Statistics</h2>
          <hr />
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th>Finalized (Live):</th>
                <td>{{ workflows.total }}</td>
              </tr>
              <tr>
                <th>Active:</th>
                <td>{{ workflows.active }}</td>
              </tr>
            </tbody>
          </table>
        </b-card>
        <b-card class="col">
          <h2 class="font-weight-bold">Timeline Statistics</h2>
          <hr />
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th>Total:</th>
                <td>{{ timelines.total }}</td>
              </tr>
              <tr>
                <th>Active:</th>
                <td>{{ timelines.active }}</td>
              </tr>
              <tr>
                <th>Completed:</th>
                <td>{{ timelines.completed }}</td>
              </tr>
              <tr>
                <th>Abandoned:</th>
                <td>{{ timelines.abandoned }}</td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </div>
      <b-card class="row mt-3">
        <div class="col">
          <h2 class="font-weight-bold">Timeline Percentages</h2>
          <hr />
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th>Active:</th>
                <td>{{ timelines.percentages.active }}%</td>
              </tr>
              <tr>
                <th>Completion:</th>
                <td>{{ timelines.percentages.completion }}%</td>
              </tr>
              <tr>
                <th>Attrition:</th>
                <td>{{ timelines.percentages.attrition }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </div>
  </ContentContainer>
</template>
<script>
import { mapGetters } from "vuex";
import ContentContainer from "@/components/templates/ContentContainer";

export default {
  components: { ContentContainer },
  data() {
    return {
      client: null,
      timelines: {
        total: 0,
        completed: 0,
        active: 0,
        abandoned: 0,
        percentages: {
          active: 0,
          completion: 0,
          attrition: 0,
        },
      },
      workflows: {
        total: 0,
        active: 0,
      },
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;

    this.client = this.getClientInContext;

    try {
      if (this.getClientInContext) {
        // refresh reporting
        await this.$store.dispatch(
          "getClientInContext",
          this.getClientInContext
        );
      } else {
        await this.$store.dispatch("getClientInstances");

        const clientID = this.$route.params.clientID;
        this.client = this.getClients.find(
          (c) => `${c.client_id}` === clientID
        );

        if (!this.client) {
          return this.$router.replace({ name: "Home" }).catch(() => {});
        } else {
          await this.$store.dispatch("getClientInContext", this.client);
          this.client = this.getClientInContext;
        }
      }
    } finally {
      if (this.client?.stats) {
        this.$bus.$emit("breadcrumbData", [
          {
            text: "Reporting Data",
            to: {
              name: "ClientReporting",
            },
          },
        ]);
        this.workflows.total =
          this.getClientInContext.stats.finalized_workflows || 0;
        this.workflows.active =
          this.getClientInContext.stats.active_workflows || 0;
        this.timelines.total = this.getClientInContext.stats.timelines || 0;
        this.timelines.active =
          this.getClientInContext.stats.active_timelines || 0;
        this.timelines.completed =
          this.getClientInContext.stats.completed_timelines || 0;
        this.timelines.abandoned =
          this.timelines.total -
          this.timelines.active -
          this.timelines.completed;
        if (this.timelines.total > 0) {
          this.timelines.percentages.active = (
            (this.timelines.active / this.timelines.total) *
            100
          ).toFixed(2);
          this.timelines.percentages.completion = (
            (this.timelines.completed / this.timelines.total) *
            100
          ).toFixed(2);
          this.timelines.percentages.attrition = (
            (this.timelines.abandoned / this.timelines.total) *
            100
          ).toFixed(2);
        } else {
          this.timelines.percentages.active = "0.00";
          this.timelines.percentages.completion = "100.00";
          this.timelines.percentages.attrition = "0.00";
        }
      }
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters([
      "getClientInContext",
      "getClients",
      "getUserIsAdministrator",
    ]),
  },
};
</script>
<style scoped>
.reporting-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
  margin-left: 0;
  margin-right: auto;
  text-align: left;
  font-size: 20px;
}
.reporting-header .strong {
  letter-spacing: 0.3px;
}
.reporting-info {
  text-align: left;
  width: 95%;
  margin: 0 auto;
}
.reporting-info h2 {
  font-size: 20px;
  font-weight: 100;
}
.row {
  width: 100%;
  margin: 0 auto;
}
a:hover,
i:hover {
  cursor: pointer;
}
</style>
